import { HttpClient, HttpParams } from '@angular/common/http';
import { effect, inject, Injectable, resource, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ToastController } from '@ionic/angular/standalone';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { pruneUndefined } from '../../shared/utils/prune-undefined';
import { AuthService } from '../auth/auth.service';
import { CardNETCustomer } from '../models/cardnet-customer';
import { Collection } from '../models/collection';
import { Resource } from '../models/resource';
import { UpdateUserDTO, User } from '../models/user';
import { UserFavoriteProduct } from '../models/user-favorite-product';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // Injectables
  readonly #logger = inject(Logger);

  private readonly auth = inject(AuthService);
  private readonly http = inject(HttpClient);
  private readonly toastCtrl = inject(ToastController);

  // Sources
  private readonly refresh$ = new BehaviorSubject<void>(void 0);

  readonly user$ = combineLatest([this.auth.state$, this.refresh$]).pipe(
    map(([state, _]) => state?.userId),
    switchMap((id) => (id ? this.getUserData(id) : of(null))),
    shareReplay(1),
  );

  readonly resource = resource({
    request: this.auth.state,
    loader: ({ request }) =>
      request?.userId ? this.get(request.userId) : Promise.resolve(null),
  });

  // Selectors
  readonly user = toSignal(this.user$);

  // State
  readonly loading = signal(false);

  constructor() {
    effect(() => this.#logger.setUser(this.user()));
  }

  private getUserData(id: User['id']) {
    const url = `${environment.apiUrl}/users/${id}`;
    return this.http.get<Resource<User>>(url).pipe(map((res) => res.data));
  }

  async get(id: User['id']) {
    const url = `${environment.apiUrl}/users/${id}`;
    const request = this.http.get<Resource<User>>(url);
    return firstValueFrom(request);
  }

  async update(dto: UpdateUserDTO) {
    const request = this.user$.pipe(
      map((user) => (user ? `${environment.apiUrl}/users/${user.id}` : null)),
      switchMap((url) =>
        url
          ? this.http.put<Resource<User>>(url, pruneUndefined(dto))
          : of(null),
      ),
      map((res) => res?.data),
      tap(() => this.refresh$.next()),
      tap(() => this.resource.reload()),
    );
    return await firstValueFrom(request);
  }

  deleteAccount() {
    this.auth.state$
      .pipe(
        switchMap((state) =>
          this.http
            .delete<{
              message: string;
            }>(`${environment.apiUrl}/users/${state?.userId}`)
            .pipe(
              tap(async ({ message }) => {
                const toast = await this.toastCtrl.create({
                  message,
                  color: 'danger',
                  duration: 3000,
                  position: 'top',
                });
                await toast.present();
              }),
            ),
        ),
      )
      .subscribe();
    this.auth.logout();
  }

  getPaymentData() {
    const request = this.auth.state$.pipe(
      switchMap((state) =>
        this.http.get<CardNETCustomer>(
          `${environment.apiUrl}/users/${state?.userId}/payment-data`,
        ),
      ),
    );
    return firstValueFrom(request);
  }

  getFavoriteProducts(page: number) {
    const params = new HttpParams().appendAll({ page });
    return this.auth.state$.pipe(
      switchMap((state) =>
        this.http.get<Collection<UserFavoriteProduct>>(
          `${environment.apiUrl}/users/${state?.userId}/favorite-products`,
          { params },
        ),
      ),
      map(({ meta, data }) => ({
        meta,
        products: data.map((data) => data.product),
      })),
    );
  }
}
